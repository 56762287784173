.app {
  text-align: center;
  font-family: 'DynaPuff';
  font-size: 22px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  margin-top: 20px;

}

.header h1 {
  font-size: 80px;
  font-family: 'DynaPuff';
}

.image-container {
  position: relative;
  width: 400px;
  height: 424px;
  margin: 0 auto;
}

.main-content {
  width: 50vw;
  display: flex;
  justify-content: space-between;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* A imagem de referência fica em z-index mais baixo */
}

.overlay-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  /* As imagens sobrepostas ficam acima da imagem de referência */
}

.overlay-section {
  margin-top: 20px;
}

.overlay-options {
  display: flex;
  justify-content: center;
}

.thumbnail {
  width: 70px;
  height: 70px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.controls {
  margin-top: 20px;
}

button {
  width: 240px;
  height: 80px;
  background-color: #c46f00;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  font-family: 'DynaPuff';
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

button:hover {
  background-color: #e25300;
}

.sec1 {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  background-image: url("./backkkk.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sec2 {
  width: 100vw;
  height: 100vh;
  background: rgb(15, 126, 41);
  background: -moz-linear-gradient(180deg, rgba(15, 126, 41, 1) 0%, rgba(0, 52, 17, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(15, 126, 41, 1) 0%, rgba(0, 52, 17, 1) 100%);
  background: linear-gradient(180deg, rgba(15, 126, 41, 1) 0%, rgba(0, 52, 17, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f7e29", endColorstr="#003411", GradientType=1);
}

body {
  overflow-x: hidden;
}

@font-face {
  font-family: 'Ghoust Outline';
  src: url('Ghoust Outline.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.left h1 {
  font-family: "Ghoust Outline";
  font-size: 120px;
  margin: 0;
  padding: 0;
  margin-top: 5%;
  color: white;
}


p {
  width: 600px;
  font-family: 'DynaPuff';
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  color: white;
  margin-top: -5%;
}

a {
  text-decoration: none;
}

.socials {
  display: flex;
  width: 600px;
  justify-content: space-between;
  margin-top: 2%;
}

.social {
  font-family: 'DynaPuff';
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 55px;
  padding-right: 55px;
  border-radius: 4px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  letter-spacing: 4px
}

.social:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.5s;
}

.buy {
  font-family: 'DynaPuff';
  width: 600px;
  height: 90px;
  border-radius: 4px;
  background-color: #45a049;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  letter-spacing: 8px;
  color: white;
  margin-top: 20px;
  animation: buy linear infinite 2s;
}


@keyframes buy {
  0% {
    rotate: 0deg;
  }

  25% {
    rotate: 1deg;
  }

  50% {
    rotate: 0deg;
  }

  75% {
    rotate: -1deg;
  }

  100% {
    rotate: 0deg;
  }
}

/* Importando as fontes */
@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400;700&family=Pixelify+Sans:wght@400;700&display=swap');


.left {
  width: 30vw;
  display: flex;
  flex-direction: column;
}

.right {
  width: 30vw;
  position: relative;
}

.right img {
  width: 680px;
  position: absolute;
  left: 20px;
  bottom: 0;
}

.line {
  width: 100vw;
  height: 5vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #2b003f;
  color: white;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.line div {
  font-family: 'DynaPuff';
  font-size: 22px;
  letter-spacing: 3px;
  animation: zoomInOut 2s infinite;
}

.buy:hover {
  animation: zoomInOut 2s infinite;
}

.sec3 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(0,139,171);
  background: -moz-linear-gradient(180deg, rgba(0,139,171,1) 0%, rgba(0,30,52,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0,139,171,1) 0%, rgba(0,30,52,1) 100%);
  background: linear-gradient(180deg, rgba(0,139,171,1) 0%, rgba(0,30,52,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008bab",endColorstr="#001e34",GradientType=1);
}

.sec3 img {
  width: 220px;
}



.env-memes {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fl,
.ll {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer {
  width: 100vw;
  height: 30vh;
  background-color: #c46f00;
  font-size: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: 'DynaPuff';
  color: white;
}

.tokenCa {
  margin-top: 20px;
  width: 600px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DynaPuff';
  cursor: pointer;
}

.remove-button {
  width: 70px;
  height: 70px;
  margin: 0;
  padding: 0;
  position: absolute;
  margin-top: 5.5px;
  right: 20vw;
}